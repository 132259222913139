import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Privacy`}</h1>
    <p>{`This page does not use Google Analytics or any other analytics software. We do not use cookies, and we do not track you.`}</p>
    <br />
    <p>{`This page is hosted on Cloudflare pages, which might lead to Cloudflare processing request numbers and request origins,
but they claim to never store personal information.`}</p>
    <br />
    <p>{`When you email us using one of the buttons or links on this website, the email will be sent directly from your mail
provider to our mail provider (Google), because we will just open the default email application on your machine. Nothing
is routed through Cloudflare.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      